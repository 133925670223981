import { showToast } from "@app/design-system";
import { useGetIncidentsIdAnnotations } from "../../../../../.rest-hooks/annotations";
import AnnotatedLineLayer from "./AnnotatedLineLayer";

interface AnnotatedLineProps {
  incidentId: string;
}

const AnnotatedLine = ({ incidentId }: AnnotatedLineProps) => {
  const { data, isError } = useGetIncidentsIdAnnotations(incidentId);
  const annotations = data?.data.data;

  if (isError) {
    showToast({
      variant: "error",
      title: "Unable to retrieve annotated line data",
    });
  }

  if (!annotations) {
    return null;
  }

  return (
    <AnnotatedLineLayer annotations={annotations} incidentId={incidentId} />
  );
};

export default AnnotatedLine;
