import { showToast } from "@app/design-system";
import { useGetIncidentsIdAnnotations } from "../../../../../.rest-hooks/annotations";
import UploadedMediaLayer from "./UploadedMediaLayer";

interface UploadedMediaProps {
  incidentId: string;
}

const UploadedMedia = ({ incidentId }: UploadedMediaProps) => {
  const { data, isError } = useGetIncidentsIdAnnotations(incidentId);
  const annotations = data?.data.data;

  if (isError) {
    showToast({
      variant: "error",
      title: "Unable to retrieve uploaded media",
    });
  }

  if (!annotations) {
    return null;
  }

  return (
    <UploadedMediaLayer annotations={annotations} incidentId={incidentId} />
  );
};

export default UploadedMedia;
