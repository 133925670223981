import { showToast } from "@app/design-system";
import { useGetIncidentsIdAnnotations } from "../../../../../.rest-hooks/annotations";
import AnnotatedTextLayer from "./AnnotatedTextLayer";

interface AnnotatedTextProps {
  incidentId: string;
}

const AnnotatedText = ({ incidentId }: AnnotatedTextProps) => {
  const { data, isError } = useGetIncidentsIdAnnotations(incidentId);
  const annotations = data?.data.data;

  if (isError) {
    showToast({
      variant: "error",
      title: "Unable to retrieve annotated text data",
    });
  }

  if (!annotations) {
    return null;
  }

  return (
    <AnnotatedTextLayer annotations={annotations} incidentId={incidentId} />
  );
};

export default AnnotatedText;
