import { showToast } from "@app/design-system";
import { useGetIncidentsIdAnnotations } from "../../../../../.rest-hooks/annotations";
import AnnotatedPolygonLayer from "./AnnotatedPolygonLayer";

interface AnnotatedPolygonProps {
  incidentId: string;
}

const AnnotatedPolygon = ({ incidentId }: AnnotatedPolygonProps) => {
  const { data, isError } = useGetIncidentsIdAnnotations(incidentId);
  const annotations = data?.data.data;

  if (isError) {
    showToast({
      variant: "error",
      title: "Unable to retrieve annotated polygon data",
    });
  }

  if (!annotations) {
    return null;
  }

  return (
    <AnnotatedPolygonLayer annotations={annotations} incidentId={incidentId} />
  );
};

export default AnnotatedPolygon;
