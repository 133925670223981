import { Button } from "@app/design-system";
import styled from "styled-components";
import formatCoordinates from "../../../config/formatCoordinates";
import { getLongFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import type { MediaAnnotation } from "../../map/IncidentAnnotations/UploadedMedia/types";
import MapPopupDialog from "../SpatialPopup/MapPopupDialog";
import { StyledText } from "../utils/AnnotationPopupDialog";

export const StyledAttributes = styled.div`
  display: grid;
  gap: 8px;
  color: ${(p) => p.theme.colors.neutrals.textWeak};
`;

export const StyledFigure = styled.div`
  display: grid;
  gap: 4px;
`;

const StyledMedia = styled.img`
  display: block;
  width: 100%;
  height: 200px;
`;

interface UploadedMediaPopupDialogProps {
  annotation: MediaAnnotation;
  onClose: () => void;
  onDeleteClick: React.MouseEventHandler<HTMLButtonElement>;
  onEditClick: React.MouseEventHandler<HTMLButtonElement>;
}

const UploadedMediaPopupDialog = ({
  annotation,
  onClose,
  onDeleteClick,
  onEditClick,
}: UploadedMediaPopupDialogProps) => {
  return (
    <MapPopupDialog
      footer={
        <>
          <Button variant="ghost" onClick={onDeleteClick}>
            Delete
          </Button>
          <Button variant="secondary" onClick={onEditClick}>
            Edit
          </Button>
        </>
      }
      onClose={onClose}
      title="Uploaded media"
    >
      <StyledAttributes>
        <StyledText>{annotation.attributes.note || <>&mdash;</>}</StyledText>
        <div>
          <StyledText>
            Author: {annotation.attributes.modifiedBy || <>&mdash;</>}
          </StyledText>
          <StyledText>
            {annotation.attributes.location ||
              formatCoordinates(annotation.attributes.geometry.coordinates)}
          </StyledText>
          <StyledText>
            {getLongFormattedDateAndTime(
              annotation.attributes.modifiedAt * 1000,
            ) || <>&mdash;</>}
          </StyledText>
        </div>
        <StyledFigure>
          <StyledMedia aria-hidden src={annotation.attributes.media.url} />
          <StyledText>
            {annotation.attributes.media?.filename || <>&mdash;</>}
          </StyledText>
        </StyledFigure>
      </StyledAttributes>
    </MapPopupDialog>
  );
};

export default UploadedMediaPopupDialog;
