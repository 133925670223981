import { showToast } from "@app/design-system";
import { useGetIncidentsIdAnnotations } from "../../../../../.rest-hooks/annotations";
import AnnotatedSymbolLayer from "./AnnotatedSymbolLayer";

interface AnnotatedSymbolProps {
  incidentId: string;
}

const AnnotatedSymbol = ({ incidentId }: AnnotatedSymbolProps) => {
  const { data, isError } = useGetIncidentsIdAnnotations(incidentId);
  const annotations = data?.data.data;

  if (isError) {
    showToast({
      variant: "error",
      title: "Unable to retrieve annotated symbol data",
    });
  }

  if (!annotations) {
    return null;
  }

  return (
    <AnnotatedSymbolLayer annotations={annotations} incidentId={incidentId} />
  );
};

export default AnnotatedSymbol;
