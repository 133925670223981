import { Button } from "@app/design-system";
import type { GISCorrection } from "../../../../.rest-hooks/types";
import { getLongFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import MapPopupDialog from "../SpatialPopup/MapPopupDialog";
import { StyledText } from "../utils/AnnotationPopupDialog";

interface FlaggedDataPopupDialogProps {
  flaggedData: GISCorrection;
  onClose: () => void;
  onDeleteClick: React.MouseEventHandler<HTMLButtonElement>;
  onEditClick: React.MouseEventHandler<HTMLButtonElement>;
}

const FlaggedDataPopupDialog = ({
  flaggedData,
  onClose,
  onDeleteClick,
  onEditClick,
}: FlaggedDataPopupDialogProps) => {
  return (
    <MapPopupDialog
      footer={
        <>
          <Button variant="ghost" onClick={onDeleteClick}>
            Delete
          </Button>
          <Button variant="secondary" onClick={onEditClick}>
            Edit
          </Button>
        </>
      }
      onClose={onClose}
      title="Flagged data"
    >
      <StyledText>{flaggedData.attributes.note || <>&mdash;</>}</StyledText>
      <StyledText>
        Data layer: {flaggedData.attributes.dataLayer || <>&mdash;</>}
      </StyledText>
      <StyledText>
        Author: {flaggedData.attributes.modifiedBy || <>&mdash;</>}
      </StyledText>
      <StyledText>
        {getLongFormattedDateAndTime(
          flaggedData.attributes.modifiedAt * 1000,
        ) || <>&mdash;</>}
      </StyledText>
    </MapPopupDialog>
  );
};

export default FlaggedDataPopupDialog;
