import type { Annotation } from "../../../../../.rest-hooks/types/annotation";
import AnnotatedLineHoverPopup from "../../../popup/AnnotatedLinePopup/AnnotatedLineHoverPopup";
import AnnotatedLinePopup from "../../../popup/AnnotatedLinePopup/AnnotatedLinePopup";
import useLayerInteractions from "../../MapInteractions/useLayerInteractions";
import { ANNOTATED_LINE_LAYER_ID } from "./constants";
import { getPropertiesFromFeature } from "./interactions";
import useAnnotatedLineMapData from "./useAnnotatedLineMapData";
import useAnnotatedLineMapLayers from "./useAnnotatedLineMapLayers";

interface AnnotatedLineLayerProps {
  annotations: Annotation[];
  incidentId: string;
}

const AnnotatedLineLayer = ({
  annotations,
  incidentId,
}: AnnotatedLineLayerProps) => {
  useAnnotatedLineMapLayers();
  useAnnotatedLineMapData({ annotations });

  const {
    deactivateClickState,
    deactivateHoverState,
    clickedState,
    hoveredState,
  } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: ANNOTATED_LINE_LAYER_ID,
  });

  return (
    <>
      <AnnotatedLineHoverPopup
        onClose={deactivateHoverState}
        state={hoveredState}
      />
      <AnnotatedLinePopup
        incidentId={incidentId}
        onClose={deactivateClickState}
        state={clickedState}
      />
    </>
  );
};

export default AnnotatedLineLayer;
