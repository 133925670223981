import { Button } from "@app/design-system";
import styled from "styled-components";
import { getLongFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import type { IncidentAnnotation } from "../../map/IncidentAnnotations/types";
import MapPopupDialog from "../SpatialPopup/MapPopupDialog";

export const StyledText = styled.div`
  color: ${(p) => p.theme.colors.neutrals.textWeak};
  ${(p) => p.theme.typography.variants.bodyDefaultStrong};
`;

interface AnnotationDialogProps {
  annotation: IncidentAnnotation;
  onClose: () => void;
  onDeleteClick: React.MouseEventHandler<HTMLButtonElement>;
  onEditClick: React.MouseEventHandler<HTMLButtonElement>;
  title: string;
}

const AnnotationPopupDialog = ({
  annotation,
  onClose,
  onDeleteClick,
  onEditClick,
  title,
}: AnnotationDialogProps) => {
  return (
    <MapPopupDialog
      footer={
        <>
          <Button variant="ghost" onClick={onDeleteClick}>
            Delete
          </Button>
          <Button variant="secondary" onClick={onEditClick}>
            Edit
          </Button>
        </>
      }
      onClose={onClose}
      title={title}
    >
      <StyledText>{annotation.attributes.note || <>&mdash;</>}</StyledText>
      <StyledText>
        Author: {annotation.attributes.modifiedBy || <>&mdash;</>}
      </StyledText>
      <StyledText>
        {getLongFormattedDateAndTime(
          annotation.attributes.modifiedAt * 1000,
        ) || <>&mdash;</>}
      </StyledText>
    </MapPopupDialog>
  );
};

export default AnnotationPopupDialog;
