import { showMarkupLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import AnnotatedLine from "./AnnotatedLine/AnnotatedLine";
import AnnotatedPolygon from "./AnnotatedPolygon/AnnotatedPolygon";
import AnnotatedSymbol from "./AnnotatedSymbol/AnnotatedSymbol";
import AnnotatedText from "./AnnotatedText/AnnotatedText";
import FlaggedData from "./FlaggedData/FlaggedData";
import UploadedMedia from "./UploadedMedia/UploadedMedia";

interface IncidentAnnotationsProps {
  incidentId: string;
}

const IncidentAnnotations = ({ incidentId }: IncidentAnnotationsProps) => {
  const { isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(showMarkupLayer.id)) {
    return null;
  }

  return (
    <>
      <AnnotatedLine incidentId={incidentId} />
      <AnnotatedPolygon incidentId={incidentId} />
      <AnnotatedSymbol incidentId={incidentId} />
      <AnnotatedText incidentId={incidentId} />
      <FlaggedData incidentId={incidentId} />
      <UploadedMedia incidentId={incidentId} />
    </>
  );
};

export default IncidentAnnotations;
