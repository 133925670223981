import { useEffect } from "react";
import type { Annotation } from "../../../../../.rest-hooks/types/annotation";
import useMapContext from "../../Map/useMapContext";
import { isGeoJsonSource } from "../../types";
import { ANNOTATED_TEXT_SOURCE_ID } from "./constants";
import {
  isTextAnnotation,
  type TextAnnotationFeature,
  type TextAnnotationFeatureCollection,
} from "./types";

interface UseAnnotatedTextMapDataParams {
  annotations: Annotation[];
}

const useAnnotatedTextMapData = ({
  annotations,
}: UseAnnotatedTextMapDataParams) => {
  const map = useMapContext();

  useEffect(() => {
    const setData = () => {
      const source = map.getSource(ANNOTATED_TEXT_SOURCE_ID);
      if (!isGeoJsonSource(source)) {
        return;
      }

      const features = annotations
        .filter(isTextAnnotation)
        .map<TextAnnotationFeature>((textAnnotation) => ({
          type: "Feature",
          properties: {
            annotationId: textAnnotation.id,
            note: textAnnotation.attributes.note,
            type: textAnnotation.attributes.annotationType,
          },
          geometry: textAnnotation.attributes.geometry,
        }));

      const featureCollection: TextAnnotationFeatureCollection = {
        type: "FeatureCollection",
        features,
      };

      source.setData(featureCollection);
    };

    setData();
  }, [annotations, map]);
};

export default useAnnotatedTextMapData;
